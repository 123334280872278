<template>
  <v-row align="center" :justify="strJustify">
    <v-col cols="auto">
      <v-img
        :src="langSrc"
        height="24"
        width="24"
        class="ml-1"
        style="outline: 1px solid black; border-radius: 100%"
      />
    </v-col>

    <v-col cols="auto">
      <p>
        {{ strTitle }}
      </p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'KDALangItem',

  props: {
    langSrc: {
      type: String,
      default: ''
    },
    strTitle: {
      type: String,
      default: ''
    },
    strJustify: {
      type: String,
      default: 'center'
    }
  }
};
</script>
